import {
    nbcLogo,
    linkedinLogo,
    verysLogo,
    makersquareLogo,
    rechargeLogo,
    gloLogo,
    flsLogo,
    netlifyLogo,
    algoliaLogo,
} from '../assets/work-logos/work-logos';

import WorkCardInfo from '../interfaces/WorkCardInfo';

export default [
    {
        company: 'Algolia',
        title: 'Customer Success Engineer (Remote)',
        stack: ['Algolia (naturally)',],
        logo: algoliaLogo,
        duration: '09/2024 - Present',
        details: [
            'Just hired!'
        ],
    } as WorkCardInfo,
    {
        company: 'Netlify',
        title: 'Senior Customer Success Engineer (Remote)',
        stack: ['React', 'Gatsby', 'Netlify'],
        logo: netlifyLogo,
        duration: '06/2023 - 06/2024',
        details: [
            'Led engineering support calls with industry accounts regarding best practices for Gatsby & Netlify applications',
            'Developed Gatsby source plugin for Drupal node generation & GraphQL schema customization',
            'Developed web applications using React, Gatsby, and Netlify',
        ],
    } as WorkCardInfo,
    {
        company: 'LinkedIn',
        title: 'Senior Web Developer (Remote)',
        stack: ['Ember.js', 'Tealium IQ'],
        logo: linkedinLogo,
        duration: '12/2021 - 06/2023',
        details: [
            'Implemented & deployed custom marketing & analytics tags & pixels within Tealium IQ',
            'Worked on a team developing features and tooling for internal marketing web app using Ember.js',
            'Served as lead designer and developer on a custom marketing tag QA tool, built in Ember.js',
            'Solved unique issues with third party integrations and created custom solutions for Tealium Tag Management system',
            'Led implementation decisions & efforts for stakeholders in a fully remote environment',
        ],
    } as WorkCardInfo,
    {
        company: 'Verys',
        title: 'Senior Software Engineer (Remote)',
        stack: ['React.js', 'Redux.js'],
        duration: '06/2021 - 12/2021',
        logo: verysLogo,
        details: [
            'Worked in a fully remote, fully distributed, environment',
            'Developed a mobile first, floor plan sketching, web app using React, Redux, TypeScript, and Redux Saga',
            'Daily meetings with clients and stakeholders, including managing expectations, discussing implementations, and brainstorming new features',
            'Delivered software and implemented last-minute changes from stakeholders under tight deadlines',
        ],
    } as WorkCardInfo,
    {
        company: 'NBC News',
        title: 'Senior JavaScript Engineer (Remote)',
        stack: ['Adobe Launch (JavaScript)', 'Flask (Python)', 'Jinja', 'Cypress.io'],
        logo: nbcLogo,
        duration: '08/2020 - 07/2021',
        details: [
            'developed, modified, and maintained custom, JavaScript based, rules & tags in Adobe Launch',
            'developed custom JS solutions for complex & specialized web application needs',
            'worked directly with stakeholders to implement solutions on tight deadlines',
            'developed custom, in-house, Adobe Launch management tools using Python',
            'implemented custom end-to-end tests with Cypress.io',
        ],
    } as WorkCardInfo,
    {
        company: 'FLS International',
        title: 'Lead Front End Developer (Remote)',
        stack: ['Gatsby.js', 'Node.js', 'Netlify CMS'],
        logo: flsLogo,
        duration: '05/2020 - 12/2020',
        details: [
            'Developed entirely custom, mobile-first, web application using React & Gatsby',
            'Entirely redesigned existing website to better conform to modern web standards',
            'Created proficiency test web app with a Node back end, Handlebars template engine, and hosted on Firebase',
            'Managed hosting & deployment of Gatsby application through Netlify',
            'Created schemas & migrated data for clients using Netlify CMS',
            'Interacted directly with clients in remote environment, including creating a Trello kanban board, regular Zoom/Google Meet conferences, and weekly reports on completed work',
        ],
    } as WorkCardInfo,
    {
        company: 'ReCharge Payments',
        title: 'Front End Developer (remote)',
        stack: ['Flask (Python)', 'Jinja', 'Vue.js', 'Shopify API (JavaScript)'],
        logo: rechargeLogo,
        duration: '03/2019 - 12/2019',
        details: [
            'integrated & developed code for third party services',
            'developed features for in-house internal tools (e.g. upload/delete functionality for custom JS snippets) ',
            'developed custom solutions for merchants & devs using the platform (e.g. a jinja filter for encrypting strings with a SHA1 hash)s',
            'gave presentations on developed features (e.g. a talk with the entire company about the work personally done for third party app integrations)',
            'worked in a fully remote, agile environment. every day tools include slack, google hangouts, google calendar, zoom, jira, and new relic.',
        ],
    } as WorkCardInfo,
    {
        company: 'Glo (formerly YogaGlo)',
        title: 'Front End Developer',
        stack: ['Ember.js', 'Node', 'Postgres', 'AWS (S3 & CloudFormation)'],
        logo: gloLogo,
        duration: '07/2016 - 03/2019',
        details: [
            'implemented front end designs and functionality using html, css, and javascript (specifically, ember.js & node)',
            'implement front end behavior and routing using express & swig',
            'implemented SASS and JS linting within CI pipeline using Grunt',
            'implemented comprehensive unit, acceptance, and integration tests using qUnit, mocha, and chai',
            'modernized & and standardized our codebase by implementing ES6 & SASS best practices through linters and Grunt tasks',
            `established team-wide philosophy: "we don't merge our own pull requests. that's what separates us from the animals.`,
        ],
    } as WorkCardInfo,
    {
        company: 'MakerSquare',
        title: 'Lead Web Development Instructor',
        stack: ['React.js', 'Node.js', 'Firebase'],
        logo: makersquareLogo,
        duration: '07/2015 - 08/2016',
        details: [
            `led web development courses for class sizes of 25+ students at MakerPrep's flagship LA location`,
            'maintained the curriculum repository, including content development, website design, and static hosting',
            'provided one-on-one tutoring for web development students',
            'in-depth debugging of student code, as well as providing guidance in best practices and refactoring',
        ],
    } as WorkCardInfo,
];
